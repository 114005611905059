// delay
@keyframes delay {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// https://animista.net/play/text/focus-in/text-focus-in
@keyframes text-focus-in {
	0% {
		filter: blur(12px);
		opacity: 0;
	}

	100% {
		filter: blur(0px);
		opacity: 1;
	}
}

// https://animista.net/play/background/ken-burns/kenburns-top-left
@keyframes kenburns-top-left {
	0% {
		transform: scale(1) translate(0, 0);
		transform-origin: 16% 16%;
	}

	100% {
		transform: scale(1.25) translate(-20px, -15px);
		transform-origin: top left;
	}
}


// https://animista.net/play/background/ken-burns/kenburns-bottom-right
@keyframes kenburns-bottom-right {
	0% {
		transform: scale(1) translate(0, 0);
		transform-origin: 84% 84%;
	}

	100% {
		transform: scale(1.25) translate(20px, 15px);
		transform-origin: right bottom;
	}
}
