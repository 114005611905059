// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$blue: #00a3e0;
$blue-gray: #4074b2;
$blue-dark: #05254d;
$gray: #484848;
$white: #fff;
$black: #000;

// theme-colors
$primary: $blue-dark;
$secondary: $blue;

// body
$body-bg: $white;
$body-color: $gray;

// links
$link-color: $blue;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;700&display=swap');
$font-family-primary: 'Outfit', sans-serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$font-weight-base: 300;
$line-height-base: 1.25;

$h1-font-size: $font-size-base * 5; // 80px
$h2-font-size: $font-size-base * 4.375; // 70px
$h3-font-size: $font-size-base * 3; // 48px
$h4-font-size: $font-size-base * 2.25; // 36px
$h5-font-size: $font-size-base * 1.5; // 24px
$h6-font-size: $font-size-base * 1.25; // 20px

$headings-font-family: $font-family-primary;
$headings-font-weight: 400;
$headings-color: $gray;
$headings-line-height: 1;

// contextual
$light: $blue;
$dark: $blue-gray;
$text-light: $white;
$text-dark: $white;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
*/
