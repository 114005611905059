// body
& {
	font-size: 20px;

	.form-control {
		font-size: 20px;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// headings
h2,
.h2 {
	color: $blue-dark;
	margin-bottom: 1.5rem;
}

h3,
.h3 {
	margin-bottom: 1.5rem;
}

h4,
.h4 {
	margin-bottom: 1.5rem;
}

h5,
.h5 {
	font-weight: 700;
}

h6,
.h6 {
	font-weight: 700;
	@extend .line-after;

	&::after {
		background-color: $blue !important;
	}
}

&.about-page {
	h2 {
		@include media-breakpoint-up(xl) {
			font-size: 3.375rem;
		}
	}
}

strong {
	font-weight: 400;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// container
.container {
	@include media-breakpoint-down(xl) {
		max-width: 1140px;
	}
}

.container-fluid {
	@media (min-width: 1400px) {
		max-width: 1660px; // 1580px / 40px

		&.container-one-column,
		&.container-two-columns,
		&.container-three-columns {
			padding-left: 40px;
			padding-right: 40px;

			.container-holder {
				margin-left: -40px;
				margin-right: -40px;

				>.column {
					padding-left: 40px !important;
					padding-right: 40px !important;
				}
			}
		}
	}
}

.container-two-columns {
	.container-holder {
		>.column.two {
			margin-left: auto;
		}
	}
}

// section-padding-x
.section-padding-x {
	@include media-breakpoint-up(md) {
		padding-left: 70px !important;
		padding-right: 70px !important;
	}
}

// form
.form {
	.form-group {
		&:first-of-type {
			label {
				font-size: 30px;
			}
		}

		textarea {
			min-height: 200px;
		}
	}

	.form-control {
		height: 48px;
		border-radius: 4px;
		box-shadow: 0 0 15px rgba($black, 0.15);
		border-color: $white;
	}
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// default-card
.default-card {
	border: none;
	border-radius: 0;
	background-color: transparent;

	.card-body {
		padding: 30px 0 0 0;

		.card-caption {
			.card-title-link {
				margin-bottom: 0.5rem;
			}

			.card-title {
				@include font-size($h5-font-size);
				margin-bottom: 0.5rem;
				font-weight: 700;

				&:hover {
					color: $blue;
				}
			}

			.card-subtitle {
				@include font-size($h6-font-size);
				margin: 0 0 0.5rem 0;
				font-weight: 700;
			}

			.card-info {
				display: flex;
				flex-flow: row wrap;

				.card-service {
					margin-right: 5px;

					&::after {
						content: "|";
					}
				}

				.card-tags {
					display: flex;
					flex-flow: row wrap;
					margin: 0;
					padding: 0;
					list-style: none;

					li {
						margin-right: 0;
						font-size: 14px;

						&::before {
							content: "/";
							margin: 0 5px;
						}

						a {
							&:hover {
								color: $blue-dark;
							}
						}
					}
				}
			}
		}
	}
}

// hr
hr {
	max-width: 300px;
	margin-left: 0;
	border-top-color: $blue;
}

// lead
.lead {
	font-size: 24px;

	@include media-breakpoint-up(xl) {
		font-size: 30px;
	}
}

// arrow-after
.arrow-after {
	&::after {
		content: "";
		display: block;
		width: 30px;
		height: 14px;
		background-image: url('/images/arrow-blue.svg');
		background-size: 30px 14px;
		margin-left: 15px;
		transition: 0.5s;
	}

	&:hover {
		&::after {
			transform: translateX(5px);
		}
	}
}

// arrow-after-white
.arrow-after-white {
	&::after {
		content: "";
		display: block;
		width: 30px;
		height: 14px;
		background-image: url('/images/arrow-white.svg');
		background-size: 30px 14px;
		margin-left: 15px;
		transition: 0.5s;
	}

	&:hover {
		&::after {
			transform: translateX(5px);
		}
	}
}

// link-arrow
.link-arrow {
	display: inline-flex;
	align-items: center;
	font-size: 20px;
	font-weight: 700;
	text-decoration: none;

	&::after {
		content: "";
		width: 30px;
		height: 14px;
		background-image: url('/images/arrow-blue.svg');
		background-size: 30px 14px;
		margin-left: 15px;
		transition: 0.5s;
	}

	&:hover {
		color: $gray;

		&::after {
			transform: translateX(5px);
		}
	}

	img {
		display: none;
	}
}

// line-before
.line-before {
	&::before {
		content: "";
		display: block;
		width: 300px;
		height: 1px;
		margin-bottom: 15px;
		background-color: $white;
		animation: line-before-slide 2s;
	}
}

@keyframes line-before-slide {
	from {
		width: 1px;
	}

	to {
		width: 300px;
	}
}

// line-after
.line-after {
	&::after {
		content: "";
		display: block;
		max-width: 300px;
		height: 1px;
		margin-top: 15px;
		background-color: $white;
	}
}

// grid-list
.grid-list {
	&.collection.grid {
		.grid-items {
			.item {
				@include make-col(12);

				&:last-of-type {
					.card {
						border-bottom: 0;
					}
				}
			}
		}

		.card {
			@extend .default-card;
			padding-bottom: 30px;
			border-bottom: 1px solid $blue;

			.card-image {
				display: none;
			}

			.card-body {
				padding-top: 0;
			}
		}
	}
}

// grid-list
.grid-list-with-image {
	&.collection.grid {
		.grid-items {
			.item {
				@include make-col(12);

				&:last-of-type {
					.card {
						border-bottom: 0;
					}
				}
			}
		}

		.card {
			@extend .default-card;
			padding-bottom: 30px;
			border-bottom: 1px solid $blue;

			.card-image {
				@include make-col(5);

				@include media-breakpoint-up(sm) {
					@include make-col(5);
				}

				padding-left: 15px;
			}

			.card-body {
				@include make-col(7);

				@include media-breakpoint-up(sm) {
					@include make-col(7);
				}

				order: -1;
				padding-top: 0;
			}
		}
	}
}

// faq-category
.page-block.faq-category {
	.faq-overview {
		.faq-category {
			.faq-items {
				.faq-item {
					border-bottom: 1px solid $blue-gray;

					&:last-of-type {
						border-bottom: none;
					}

					.faq-question {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 10px 0;
						cursor: pointer;

						a {
							display: none;
						}

						.faq-category {
							h3 {
								margin-bottom: 0;
								color: $blue-dark;
								font-size: 20px;
								transition: 0.5s;

								@include media-breakpoint-up(xl) {
									font-size: 24px;
								}
							}
						}

						.faq-icon {
							color: $blue;
							font-size: 20px;

							@include media-breakpoint-up(xl) {
								font-size: 24px;
							}

							i {
								&::before {
									content: "\f067";
									font-weight: 400;
									transition: 0.5s;
								}
							}
						}

						&:hover {
							.faq-category {
								h3 {
									color: $blue;
								}
							}

							.faq-icon {
								color: $blue-dark;
							}
						}
					}

					.faq-answer {
						display: none;
						font-size: 20px;
					}

					&.active {
						.faq-question {
							.faq-icon {
								i {
									&::before {
										content: "\f068";
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
