// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 8vh;
		padding-bottom: 8vh;
	}
}

// eyecatcher-section
// =========================================================================
.eyecatcher-section {
	position: relative;

	@include media-breakpoint-down(sm) {
		padding-top: 82px;
	}

	background-color: $blue;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		@include media-breakpoint-down(sm) {
			display: none !important;
		}

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		&::before {
			display: none !important;
		}

		.item {
			position: relative;
			align-items: flex-start;
			min-height: 300px;
			overflow: hidden;

			@include media-breakpoint-up(md) {
				padding: 0 70px;
				height: 100vh;
			}

			@media (min-width: 1400px) {
				padding: 0 95px;
			}

			@include media-breakpoint-down(sm) {
				flex-direction: column-reverse;
			}

			picture {
				display: none;
				/*
				z-index: 10;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 60%;
				animation: delay 4s 4s both;
				overflow: hidden;

				@include media-breakpoint-down(sm) {
					position: relative;
					width: 90%;
					margin-left: 10%;
				}

				@media (min-width: 1200px) {
					width: 50%;
				}

				@media (min-width: 1400px) {
					width: 40%;
				}

				&::after {
					content: "";
					z-index: 20;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-image: url('/images/eyecatcher-mask.svg');
					background-size: cover;
				}

				img {
					height: 100vh;
					object-fit: cover;

					@include media-breakpoint-down(sm) {
						width: auto;
						height: auto;
					}
				}
				*/
			}

			.owl-caption-holder {
				position: relative;
				z-index: 30; // above picture
				align-self: center;

				.owl-container {
					max-width: 1140px;

					.owl-caption {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						padding: 8vh 0;
						text-shadow: none;
						text-align: left;

						.owl-title {
							max-width: 1110px;
							color: $white;
							@include font-size($h1-font-size);

							@extend .line-before;

							&::before {
								content: "";
								max-width: 50%;
								animation-delay: 1s;
							}

							@include media-breakpoint-up(xl) {
								font-size: clamp(2.5rem, 7.5vw, 7.5rem); // 120px
							}

							animation: delay 1s 1s both;
						}

						.owl-subtitle {
							max-width: 600px;
							@include font-size($h4-font-size);
							margin-bottom: 0;
							animation: delay 2s 2s both;
						}

						.owl-btn {
							margin-top: 6vh;
							border-color: $blue-dark;
							background-color: $blue-dark;
							animation: delay 3s 3s both;

							@include media-breakpoint-up(xl) {
								padding-left: 35px;
								padding-right: 35px;
							}

							&:hover {
								border-color: $blue-gray;
								background-color: $blue-gray;
							}
						}
					}
				}
			}
		}

		.owl-item {
			&.active {
				.item {
					&.animation-people {
						img {
							animation: kenburns-top-left 10s ease both;
						}
					}

					&.animation-work {
						img {
							animation: kenburns-bottom-right 10s ease both;
						}
					}

					.owl-caption {
						animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
					}
				}
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding-top: 75px !important;
	padding-bottom: 8vh !important;
	@extend .section-padding-x;

	@include media-breakpoint-up(xl) {
		padding-top: 160px !important;
		padding-bottom: 100px !important;
		font-size: 30px;
	}

	h1 {
		@extend .line-before;
	}

	h1.large {
		@include media-breakpoint-up(xl) {
			font-size: clamp(2.5rem, 7.5vw, 7.5rem); // 120px
		}
	}

	.info-nav {
		display: none;
	}

	.container-one-column {
		.container-holder {
			max-width: 1000px;

			.column {
				&.narrow {
					@include media-breakpoint-up(md) {
						max-width: 830px;
					}
				}
			}
		}
	}

	.container-two-columns {
		.container-holder {
			.column {
				&.wide {
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
				}

				&.narrow {
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}

				&.sidebar {
					@extend .line-before;

					@include media-breakpoint-up(xl) {
						font-size: 20px;
					}

					.wysiwyg {
						margin-top: 15px;
					}
				}
			}
		}
	}

	// blog-category-filter
	.blog-category-filter {
		margin-bottom: 0;

		.list {
			.list-item {
				margin-right: 0;
				font-size: 20px;

				+li {
					&::before {
						content: "/";
						margin: 0 5px;
					}
				}

				.list-link {
					&:hover {
						color: $blue-dark;
					}
				}
			}
		}
	}
}

&.contact-page {
	.lead-section {
		background-color: $blue-dark !important;

		.faq-overview {
			max-width: 560px;
		}
	}
}

&.service-detail {
	.lead-section {
		.logo {
			margin: 30px 0 auto auto;
		}
	}
}


&.service-detail,
&.employee-overview,
&.blog-post-overview,
&.blog-post-detail,
&.vacancy-vacancy-overview,
&.vacancy-vacancy-detail {
	.lead-section {
		padding-bottom: 60px !important;
	}
}

// lead-banner-section
// =========================================================================
.lead-banner-section {
	position: relative;
	@extend .section-padding-x;

	.container-holder {
		align-items: flex-start;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 40%;
		background-color: $blue;
	}

	// wysiwyg
	.wysiwyg {
		&:first-of-type {
			@include media-breakpoint-up(md) {
				color: $white;
			}

			@include media-breakpoint-up(xl) {
				font-size: 30px;
			}
		}

		&:last-of-type {
			@include media-breakpoint-up(md) {
				margin-top: 40%;
			}

			@include media-breakpoint-up(xl) {
				margin-left: 80px;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		min-height: auto !important;

		.item {
			min-height: auto !important;
			padding: 0 !important;
		}
	}

}

&.vacancy-vacancy-overview {
	.lead-banner-section {
		&::before {
			background-color: $blue-gray;
		}
	}
}

// intro-section
// =========================================================================
.intro-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	.container-one-column {
		.container-holder {
			max-width: 1000px;

			.column {
				&.narrow {
					@include media-breakpoint-up(md) {
						max-width: 820px;
					}
				}
			}
		}
	}

	.wysiwyg {
		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}
	}
}

// clients-section
// =========================================================================
.clients-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	h2 {
		@include media-breakpoint-down(xs) {
			font-size: 30px;
		}
	}

	// collection
	.collection {
		margin-top: 5rem;

		@include media-breakpoint-up(xl) {
			padding-left: 30%;
		}

		&.grid {
			.grid-items {
				.item {
					@include make-col(4);

					@include media-breakpoint-up(sm) {
						@include make-col(4);
					}

					@include media-breakpoint-up(md) {
						@include make-col(3);
					}

					@include media-breakpoint-up(lg) {
						flex: 0 0 20%;
						max-width: 20%;
					}
				}
			}
		}

		.card {
			border: none;
			border-radius: 0;

			.card-image {
				filter: grayscale(1);

				&:hover {
					filter: grayscale(0);
				}
			}

			.card-body {
				display: none !important;
			}
		}
	}
}

// latest-work-section
// =========================================================================
.latest-work-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// highlight
	.highlight {
		.card {
			@extend .default-card;
			margin-top: 6rem;

			.card-subtitle {
				display: none;
			}
		}
	}

	// link
	div.link {
		display: flex;

		@include media-breakpoint-up(md) {
			margin: auto 0 auto auto;
		}

		a.link {
			@extend .btn;
			@extend .btn-primary;

			&:hover {}
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	padding-top: 0 !important;
	padding-bottom: 0 !important;

	.container-two-columns {
		max-width: 2560px !important;
		padding-left: 0 !important;
		padding-right: 0 !important;

		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}

		.container-holder {
			align-items: center;
			margin: 0 !important;

			>.column {
				margin-bottom: 0;

				&.one {
					align-self: center;
					padding-top: 45px;
					padding-bottom: 45px;

					@include media-breakpoint-up(md) {
						padding-left: 85px !important;
					}

					@media (min-width: 1400px) {
						padding-left: 110px !important;
					}

					.wysiwyg {
						max-width: 750px;
						margin-left: auto;
					}
				}

				&.two {
					align-self: stretch;
					padding-right: 0 !important;

					@include media-breakpoint-down(sm) {
						padding-left: 0 !important;
					}


					img {
						min-height: 400px;
						height: 75vh;
						max-height: 880px;
						object-fit: cover;
					}
				}
			}
		}
	}

	.wysiwyg {
		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}
	}

}

// content-section
// =========================================================================
.content-section {
	margin: 8vh 0;

	@extend .section-padding-x;

	.container-one-column {
		.container-holder {
			.column {
				&.narrow {
					@include media-breakpoint-up(md) {
						max-width: 820px;
					}
				}
			}
		}
	}

	// faq-row
	.faq-row {
		margin-top: 8vh;

		@include media-breakpoint-up(xl) {
			margin-top: 80px;
		}

		&:nth-child(even) {
			@include media-breakpoint-down(sm) {
				.container-holder {
					flex-direction: column-reverse;
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {

	// owl-carousel
	.owl-carousel {

		.item {
			max-height: 880px;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.15);
			}

			picture {
				overflow: hidden;

				img {
					width: 100vw;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				@include media-breakpoint-up(md) {
					padding-left: 70px;
					padding-right: 70px;
				}

				.owl-container {
					position: relative;
					height: 100%;

					@media (min-width: 1400px) {
						max-width: 1660px;
						padding-left: 40px;
						padding-right: 40px;
					}

					.owl-caption {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						max-width: 1000px;
						height: 100%;
						padding: 8vh 0;
						text-shadow: none;
						text-align: left;

						.owl-title {
							margin-bottom: 0;
							color: $white;
							@include font-size($h1-font-size);
							@extend .line-before;
						}

						.owl-subtitle {
							margin-bottom: 0;
						}

						.owl-btn {
							margin-top: auto;
						}
					}
				}
			}

			.card {
				.card-img-overlay {
					display: none;
				}
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				@include media-breakpoint-up(xl) {
					margin: 0 -40px -80px -40px;
				}

				.item {
					@include media-breakpoint-up(xl) {
						margin-bottom: 80px;
						padding-left: 40px;
						padding-right: 40px;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card;
					}
				}
			}
		}
	}
}

&.service-overview {
	.bundle-overview-section {
		position: relative;
		margin-top: 0;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 200px;
			background-color: $blue;
		}

		// collection
		.collection {
			.card {
				.card-subtitle {
					display: none;
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 8vh 0;
}

// latest-vacancy-section
// =========================================================================
.latest-vacancy-section {
	@extend .section-padding-x;

	// collection
	.collection {
		margin-top: 8vh;

		.card {
			@extend .default-card;
			@extend .bg-dark;
		}
	}
}

// employee-bundle-overview-section
// =========================================================================
.employee-bundle-overview-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin-bottom: -45px;

				.item {
					@include make-col(6);
					margin-bottom: 45px;

					@include media-breakpoint-up(md) {
						@include make-col(4);
					}

					@include media-breakpoint-up(lg) {
						@include make-col(3);
					}

					@include media-breakpoint-up(xl) {
						flex: 0 0 20%;
						max-width: 20%;
					}

					.card {
						@extend .default-card;
						padding-bottom: 15px;
						border-bottom: 1px solid $blue;

						.card-body {
							padding-top: 15px;

							.card-description {
								margin-top: 15px;

								p:last-of-type {
									margin-bottom: 0;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// vacancy-bundle-overview-section
// =========================================================================
.vacancy-bundle-overview-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		margin: 45px 0;

		&.grid {
			.grid-items {
				.item {
					@include make-col(12);
					margin-bottom: 45px;

					.card {
						@extend .default-card;

						.card-image {
							display: none;
						}

						.card-body {
							padding-top: 0;

							.card-caption {
								.card-title {
									@include font-size($h4-font-size);
									font-weight: 400;
								}
							}
						}
					}
				}
			}
		}
	}
}

// blog-bundle-overview-section
// =========================================================================
.blog-bundle-overview-section {
	margin-bottom: 8vh;
	@extend .section-padding-x;

	// .blog-filter
	.blog-filter,
	.blog-category-filter {
		display: none;
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				@include media-breakpoint-up(xl) {
					margin: 0 -40px -40px -40px;
				}

				.item {
					@include media-breakpoint-up(xl) {
						margin-bottom: 40px;
						padding-left: 40px;
						padding-right: 40px;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card;

						.card-body {
							.card-caption {
								.card-subtitle {
									font-size: 14px;
									font-weight: 400;
								}
							}
						}
					}

					&:not(:nth-child(-n+2)) {
						.card {
							.card-image {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// blog-detail-intro-section
// =========================================================================
.blog-detail-intro-section {
	@extend .section-padding-x;

	// image-collection
	.image-collection {
		.gallery-horizontal {
			.gallery-item {
				@include make-col(12);
			}

			.gallery-thumbs {
				display: none;
			}
		}
	}

	.info-nav {
		display: none;
	}

	.info {
		display: flex;
		flex-direction: column;

		.info-holder {
			display: flex;
			flex-flow: row wrap;
			margin-bottom: 30px;
		}

		.subtitle {
			order: 0;

			h5 {
				margin-bottom: 0;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.tags {
			display: flex;
			flex-flow: row wrap;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 0;
				font-size: 14px;

				&::before {
					content: "/";
					margin: 0 5px;
				}

				a {
					&:hover {
						color: $blue-dark;
					}
				}
			}
		}

		.wysiwyg {
			order: 1;
			position: relative;
			margin-bottom: 30px;

			@include media-breakpoint-up(xl) {
				font-size: 30px;
			}

			@extend .line-after;

			&::after {
				margin-top: 30px;
				background-color: $blue-gray !important;
			}
		}

		.title {
			order: 2;
		}
	}
}


// vacancy-vacancy-detail
// =========================================================================
&.vacancy-vacancy-detail {

	// button-cv
	div.button-cv {
		position: fixed;
		top: 15px;
		right: 15px;

		a {
			@extend .btn;
			@extend .btn-primary;
		}
	}
}

// services-detail
// =========================================================================
&.service-detail {
	.gallery-section {
		@extend .bg-dark;
		@extend .section-padding-x;
		overflow: hidden;

		// heading-holder
		.heading-holder {
			.column {
				flex-flow: row wrap;
			}

			h2 {
				@include media-breakpoint-up(xl) {
					font-size: 60px;
				}
			}

			.domain-link {
				display: flex;

				@include media-breakpoint-up(sm) {
					margin: auto 0 auto auto;
				}

				a {
					@extend .arrow-after-white;
					display: inline-flex;
					align-items: center;
					color: $white;
					font-weight: 700;
					text-decoration: none !important;
				}
			}
		}

		// gallery-holder
		.gallery-holder {
			width: 100vw;
			max-width: none;

			.container-holder {
				.column {
					.work-slider {
						.owl-stage {
							padding: 20px 0;
						}

						.gallery-link {
							display: block;
							box-shadow: 0 0 15px rgba($black, 0.15);
						}
					}
				}
			}
		}
	}
}

// other-services-section
// =========================================================================
.other-services-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		.card {
			@extend .default-card;

			.card-subtitle {
				display: none;
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}
