.menu-overlay {
	z-index: 997;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100% !important;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 65px;
	background-color: $blue-gray;
	transition: 0.75s;
	transform: translate(-100%, 0%);

	.container-two-columns {
		transform: translate(-50%, 0%);
		transition: 1s 0.25s;

		.container-holder {
			align-items: flex-end;

			.column {
				&.one {
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
				}

				&.two {
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}
			}
		}
	}

	&.collapse:not(.show) {
		display: flex !important;
	}

	&.show {
		transform: translate(0%, 0%);
		background-color: $blue-dark;

		.container-two-columns {
			transform: translate(0%, 0%);
		}
	}

	.menu {
		display: block !important;

		.navbar-nav {
			.nav-item {
				@include font-size($h1-font-size);
				color: $white;
				font-weight: 400;
				line-height: 1;

				@include media-breakpoint-up(xl) {
					font-size: 100px;
				}

				.nav-link {
					padding: 0.25rem 0;
					color: $white;

					&:hover {
						padding-left: 10px;
						color: $blue;
					}
				}

				&.active:not(.dropdown) {
					.nav-link {
						padding-left: 10px;
						color: $blue;
					}
				}

				&.dropdown {
					.dropdown-toggle {
						&::after {
							display: none;
						}
					}

					.dropdown-menu {
						display: flex;
						flex-wrap: wrap;
						position: static !important;
						transform: none !important;
						margin: 10px 0;
						padding: 0;
						border: none;
						border-radius: 0;
						box-shadow: none;
						background: transparent;

						&::before,
						&::after {
							display: none;
						}

						.nav-item {
							display: flex;
							align-items: center;
							font-size: 24px;

							@include media-breakpoint-up(xl) {
								font-size: 30px;
							}

							+li {
								&::before {
									content: "/";
									margin: 0 5px;
								}
							}
						}
					}
				}
			}
		}

		// menu-small
		&.menu-small {
			margin-top: 30px;

			.navbar-nav {
				.nav-item {
					font-size: 20px;

					@include media-breakpoint-up(xl) {
						font-size: 30px;
					}
				}
			}
		}
	}

	// contact-info
	div.contact-info {
		margin-top: 45px;

		ul {
			flex-direction: column;

			li {
				a {
					color: $white;
					text-decoration: underline;

					&:hover {
						color: $blue;
					}
				}
			}
		}
	}

}
