// header
.header {
	@include media-breakpoint-down(sm) {
		z-index: 998;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: background-color 0.5s;

		&.sticky {
			background-color: rgba($blue, 0.9);
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 999;

		@include media-breakpoint-down(sm) {
			margin-left: 15px;
		}

		@include media-breakpoint-up(md) {
			position: fixed;
			top: 50%;
			left: 15px;
			transform: translateY(-50%);
		}

		.navbar-toggler {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45px;
			height: 45px;
			padding: 0;
			border: none;
			background: $white;
			border-radius: 100%;
			color: $gray;
			font-size: 28px;
			font-weight: 400;
			text-transform: uppercase;
			transition: 0.5s;

			@include media-breakpoint-up(md) {
				width: 55px;
				height: 55px;
				background: #e5f5fb;
			}

			i {
				transition: transform 0.5s;
			}

			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);

					&::before {
						content: "\f00d";
						font-size: 34px;
						font-weight: 300;
					}
				}
			}

			&:hover {
				background: $blue-dark;
				color: $white;
			}
		}
	}

	// logo
	.logo {
		@include media-breakpoint-up(md) {
			z-index: 998;
			position: fixed;
			top: 15px;
			left: 15px;
		}

		margin-right: auto;

		a {
			display: flex;
			align-items: center;

			@include media-breakpoint-up(sm) {
				min-width: 290px;
			}

			img {
				@extend .w-100;
				transition: transform .5s;
			}

			&:hover {
				img {
					transform: scale(1.1);
				}
			}

			&::after {
				content: "";
				z-index: -1;
				transition: .75s;
				opacity: 0;
				transform: translateX(-55px);
			}
		}

		&.logo-white {
			a {
				&:hover {
					&::after {
						content: "";
						position: absolute;
						left: 134px;
						width: 156px;
						height: 32px;
						background-image: url('/images/logo-text-white.svg');
						background-size: 156px 32px;
						transform: translateX(0);
						opacity: 1;
					}
				}
			}
		}

		&.logo-blue {
			a {
				&:hover {
					&::after {
						content: "";
						position: absolute;
						left: 134px;
						width: 156px;
						height: 32px;
						background-image: url('/images/logo-text-blue.svg');
						background-size: 156px 32px;
						transform: translateX(0);
						opacity: 1;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		z-index: 997;
		position: absolute;
		top: 35px;
		right: 45px;

		@include media-breakpoint-down(sm) {
			position: static;
		}

		.navbar-nav {
			.nav-item {
				font-weight: 300;

				+li {
					margin-left: 10px;
				}

				.nav-link {
					color: $white;
					border: none;
					border-radius: 0;

					&:hover {
						box-shadow: none !important;
						color: $blue-gray;
					}
				}

				&.active {
					font-weight: 700;
				}
			}
		}
	}

}

&.home {
	.header {
		@include media-breakpoint-up(sm) {
			.logo {
				animation: delay 1s 5s both;
			}
		}
	}
}
